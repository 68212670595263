<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-input v-model="options.params.keyword" clearable placeholder="标题搜索"/>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
            </template>
        </crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'admin.Article.add',
                addPerm: 'admin_Article_add',
                editUrl: 'admin.Article.edit',
                editPerm: 'admin_Article_edit',
                delUrl: 'admin.Article.delete',
                delPerm: 'admin_Article_delete',
                listUrl: 'admin.Article.page',
                formLabelWidth: '100px',
                params: {
                    keyword: null
                },
                columns: [
                    {
                        prop: "article_category_id",
                        label: "所属分类",
                        type: 'cascader',
                        dicUrl: 'admin.ArticleCategory.tree',
                        dicData: [],
                        dicProps: {label: 'name', value: 'id'},
                        formatter: row => {
                            return row.category ? row.category.name : null
                        }
                    },
                    {prop: "title", label: "标题", required: true},
                    {
                        prop: "cover", label: "封面", type: 'image',
                        width: 72,
                        html: ({row}) => {
                            return row.cover ? `<img class="list-user-avatar" src="${row.cover}" alt="加载失败"/>` : null
                        }
                    },
                    {prop: "content", label: "内容", hide: true, type: 'editor', required: true},
                    {prop: "sort", label: "排序", type: "number", required: true, value: 0},
                    {prop: "remark", label: "备注",}
                ],
            }
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
